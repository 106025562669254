import React, { useState, useEffect } from 'react';
import TimePicker from './TimePicker'; 
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import '../index.css';

const CustomDatePicker = ({ type, selectedDate, handleDateChange, availableDates, availableTimes, setSelectedTime, currentSelectedTime, setCurrentSelectedTime, language }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [filteredTimes, setFilteredTimes] = useState([]);

  const isDateAvailable = (date) => {
    const day = date.getDay(); // Get the day of the week (0 = Sunday, ..., 6 = Saturday)

    const excludedSpecificDates = [
      '2024-12-31',
      '2024-01-01'
    ];

    // Exclude Tuesdays (2) and Wednesdays (3)
    // if type == 1 is how I make it work for virginie
    if ( type == 1 && (day === 2 || day === 3 )) {
      return false;
    }
    
    // Remove specific dates only if type === 1
    if (type == 1) {
      const formattedDate = date.toISOString().split('T')[0]; // Format date as 'YYYY-MM-DD'
  
      if (excludedSpecificDates.includes(formattedDate)) {
        return false;
      }
    }

    // Check if the date exists in the availableDates array
    return availableDates.some(d =>
      d.getFullYear() === date.getFullYear() &&
      d.getMonth() === date.getMonth() &&
      d.getDate() === date.getDate()
    );
  };
  
  const getFirstAvailableDate = () => {
    return availableDates.length > 0 ? availableDates[0] : new Date();
  };

  useEffect(() => {
    setStartDate(getFirstAvailableDate());
  }, [availableDates]);

  // Update filtered times when the selected date changes
  useEffect(() => {
    if(type ==='0'){
      const dateIndex = availableDates.findIndex((availableDate) => {
        return availableDate.getFullYear() === selectedDate.getFullYear() &&
              availableDate.getMonth() === selectedDate.getMonth() &&
              availableDate.getDate() === selectedDate.getDate();
      });

      if (dateIndex !== -1) {
        const timesForSelectedDate = availableTimes[dateIndex] || [];
        setFilteredTimes(timesForSelectedDate);
        
        // Only set the current selected time if it is not in the new available times
        if (!timesForSelectedDate.includes(currentSelectedTime)) {
          const defaultTime = timesForSelectedDate[0] || '';
          setCurrentSelectedTime(defaultTime); // Set default time
          setSelectedTime(defaultTime); // Update parent state
        }
      } else {
        setFilteredTimes([]);
        setCurrentSelectedTime(''); // Reset local selected time if no times are available
        setSelectedTime(''); // Reset parent selected time
      }
    }else{
      setFilteredTimes(availableTimes)
    }
  }, [selectedDate, availableDates, availableTimes, currentSelectedTime, setSelectedTime]);

  const handleTimeChange = (time) => {
    setCurrentSelectedTime(time); // Update local state
    setSelectedTime(time); // Update parent state
  };

  return (
    <Form.Group controlId="formDate" style={{marginTop: '5px'}}>
      <Form.Label>
        {language === 'EN' ? 'Select a date' : 
        language === 'FR' ? 'Sélectionner une Date' : 
        language === 'SP' ? 'Selecciona una fecha': ""}
      </Form.Label>
      <div className="date-picker-container">
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          filterDate={isDateAvailable}
          inline
          openToDate={startDate}
        />
      </div>
      <TimePicker 
        availableTime={filteredTimes} 
        handleTimeChange={handleTimeChange}  
        selectedTime={currentSelectedTime} 
        language={language}
      />
    </Form.Group>
  );
};

export default CustomDatePicker;
